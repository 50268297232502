import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { StorageService } from '../_services/storage.service';
import { AuthService } from '../components/auth/auth.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent implements OnInit {
    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    userName: string = '';

    constructor(
        public layoutService: LayoutService, 
        public el: ElementRef,
        private storageService: StorageService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        if (this.storageService.getUser()) {
            this.userName = this.storageService.getUser().profile.name
        }
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showRightMenu();
    }

    onSearchClick() {
        this.layoutService.toggleSearchBar();
    }

    onRightMenuClick() {
        this.layoutService.showRightMenu();
    }

    get logo() {
        const logo =
            this.layoutService.config().menuTheme === 'white' ||
            this.layoutService.config().menuTheme === 'orange'
                ? 'dark'
                : 'white';
        return logo;
    }

    logout(): void {
        this.authService.logout(this.storageService.getUser().id_token).catch(err => console.log(err))
    }
}
