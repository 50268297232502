export const environment = {
    production: false,
    apiUrl: 'https://beta.emsitpro.com/dev/api/v1',
    httpCredential: false,
    stsAuthority: 'https://sso.emsitpro.com/',
    clientId: 'emserp_web_app',
    clientSecret: '55d33e6c-557b-5e57-c009-cdfdbf6f183f',
    clientRoot: 'https://beta.emsitpro.com/',
    clientScope: 'openid profile email roles emserp_backend_api ems_identity_admin_api',
    weblogUrl: 'https://beta.emsitpro.com/dev/api/callback/weblog',
    apiSsoUrl: 'https://api.sso.emsitpro.com'
};
