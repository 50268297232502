import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { User } from 'oidc-client-ts';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  
  readonly authService = inject(AuthService);

  currentUser: User | null = null;

  constructor (
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    
  }

  loginSSO(): void {
    this.authService.login().catch(err => {
      this.logger.error(err)
    });
  }
  
}
