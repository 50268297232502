import { Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client-ts';
import { StorageService } from 'src/app/_services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userManager: UserManager;

  constructor(
    private storageService: StorageService,
  ) { 
    const settings = {
      authority: environment.stsAuthority,
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      redirect_uri: `${environment.clientRoot}signin-callback`,
      silent_redirect_uri: `${environment.clientRoot}silent-callback.html`,
      post_logout_redirect_uri: `${environment.clientRoot}public/login`,
      response_type: 'code',
      scope: environment.clientScope,
    };
    this.userManager = new UserManager(settings);
  }

  getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  renewToken(): Promise<User | null> {
    return this.userManager.signinSilent();
  }

  async logout(idtoken: string): Promise<void> {
    this.storageService.clean();
    await this.userManager.signoutRedirect({
      id_token_hint: idtoken,
      post_logout_redirect_uri: `${environment.clientRoot}public/login`,
    });
    this.userManager.clearStaleState();
  }
}
