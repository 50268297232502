import { Injectable } from '@angular/core';
import { LoginDTO } from '../components/auth/login/model/login';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clean(): void {
    window.sessionStorage.clear();
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): LoginDTO {
    let obj: LoginDTO = {
      access_token: '',
      id_token: '',
      profile: {
        email: '',
        name: '',
        role: '',
        sub: ''
      }
    };
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      obj = JSON.parse(user);
    }
    return obj;
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }
}
