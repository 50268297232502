import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { loginGuard } from './_shared/login.guard';
import { authGuard } from './_shared/auth.guard';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    useHash: false
};

const routes: Routes = [
    { path: '', redirectTo: 'public/login', pathMatch: 'full' },
    {
        path: 'public',
        component: LoginComponent,
        canActivate: [loginGuard],
        children: [
            { path: 'login', loadChildren: () => import('./components/auth/login/login.module').then(m => m.LoginModule) },
        ]
    },
    {
        path: 'private',
        component: AppLayoutComponent,
        canActivate: [authGuard],
        children: [
            { path: 'home/dashboard', data: {breadcrumb: 'Dasbor'}, loadChildren: () => import('./components/home/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'home/calendar', data: {breadcrumb: 'Kalender Kerja'}, loadChildren: () => import('./components/home/calendar/calendar.module').then(m => m.CalendarModule) },
            { path: 'auth/usergroup', data: {breadcrumb: 'Grup Pengguna'}, loadChildren: () => import('./components/auth/usergroup/usergroup.module').then(m => m.UsergroupModule) },
            { path: 'auth/user', data: {breadcrumb: 'Pengguna'}, loadChildren: () => import('./components/auth/user/user.module').then(m => m.UserModule) },
            { path: 'utilities/utility', data: {breadcrumb: 'Utilitas'}, loadChildren: () => import('./components/utilities/utility/utility.module').then(m => m.UtilityModule)},
            { path: 'utilities/reference', data: {breadcrumb: 'Referensi'}, loadChildren: () => import('./components/utilities/reference/reference.module').then(m => m.ReferenceModule)},
            { path: 'utilities/log-audit', data: {breadcrumb: 'Log Audit'}, loadChildren: () => import('./components/utilities/logaudit/log-audit.module').then(m => m.LogAuditModule)},
            { path: 'utilities/log-service', data: {breadcrumb: 'Log Service'}, loadChildren: () => import('./components/utilities/logservice/log-service.module').then(m => m.LogServiceModule)},
        ]
    },
    { path: 'signin-callback', loadChildren: () => import ('./components/auth/signincallback/signin-callback.module').then(m => m.SigninCallbackModule) },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
